import React from 'react';

export default function ContactAddress2() {
  return (
    <>
      <p><i className="fas fa-home mr-3"></i>Famphy Clinic # 4/3, D R Pride Complex, Opp. to BDS Nagar, K Narayanapura Main Road, Kothanur Post, <br/>Bangalore – 5600 77</p>
      <p><i className="fas fa-envelope mr-3"></i> hello@famphy.com</p>
      <p><i className="fas fa-phone mr-3"></i> +91 96 0615 8631</p>
      <iframe
        title="Famphy Location on Google Maps" // Add a title here
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31092.34805713694!2d77.61111931083981!3d13.06470329999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae19266d981383%3A0xc39a2b97fc1b1f5e!2sFamily%20Clinics%20-%20Unit%20of%20Famphy!5e0!3m2!1sen!2sin!4v1731765722387!5m2!1sen!2sin"
        width="100%"
        height="300"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </>
  );
}
