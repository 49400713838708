import React from 'react';
import { Link } from 'react-router-dom';

export default function HeroBaner() {
  return (
    <>
      <div id="carouselExampleCaptions" className="carousel slide home-hero-banner">
        <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div>
        <div className="carousel-inner">
          <div className="carousel-item active">
          <img src={`${process.env.PUBLIC_URL}/img/famphy-hero-banner-01.jpg`} alt="Famphy banner" />
            <div className="carousel-caption d-flex flex-column h-100 align-items-start justify-content-center bottom-0">
              <h1>Healthcare at <span>your Doorstep</span></h1>
              <div className="hero-btn-box">
                <Link to="/book-appointment" className="btn theam-btn-m">Book appointment</Link>
                <Link to="/our-services" className="btn theam-btn-m">Our Services</Link>
              </div>
              <p>We offer an extensive network of Family physicians with +15 years of experience who provide healthcare at your home in Delhi ,NCR and Bengaluru</p>
            </div>
          </div>
          <div className="carousel-item">
            <img src={`${process.env.PUBLIC_URL}/img/famphy-hero-banner-03.jpg`} alt="Famphy banner" />
            <div className="carousel-caption d-flex flex-column h-100 align-items-start justify-content-center bottom-0">
              <p>With FamPhy Doctors</p>
              <h1>Quick Medical <span>Attention</span></h1>
              
              <p>Quick, affordable and personalized medical attention at the comfort of your home</p>
              <div className="hero-btn-box">
                <Link to="/book-appointment" className="btn theam-btn-m">Book appointment</Link>
                <Link to="/our-services" className="btn theam-btn-m">Our Services</Link>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img src={`${process.env.PUBLIC_URL}/img/famphy-hero-banner-02.jpg`} alt="Famphy banner" />
            <div className="carousel-caption d-flex flex-column h-100 align-items-start justify-content-center bottom-0">
              <h1>Healthcare at <span>your Doorstep</span></h1>
              <div className="hero-btn-box">
                <Link to="/book-appointment" className="btn theam-btn-m">Book appointment</Link>
                <Link to="/our-services" className="btn theam-btn-m">Our Services</Link>
              </div>
              <p>We offer an extensive network of Family physicians with +15 years of experience who provide healthcare at your home</p>
            </div>
          </div>
          <div className="carousel-item">
          <video 
  autoPlay 
  muted 
  loop 
  playsInline
  style={{ width: '100%', height: 'auto' }}
>
  <source src={`${process.env.PUBLIC_URL}/img/famphy-clinic-new-open.mp4`} type="video/mp4" />
  Your browser does not support the video tag.
</video>
          </div>

          


        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </>
  );
}
